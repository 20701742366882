export const DEFAULT_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";

export const BASE_AUTH_URL = `${DEFAULT_URL}/users`;

export const authHeaderKeys = ['uid', 'authorization'];

export const APP_STORE_URL = process.env.REACT_APP_APP_STORE_URL || 'https://apps.apple.com/us/app/secure-start/id1641963424';
export const GOOGLE_PLAY_STORE_URL = process.env.REACT_APP_GOOGLE_PLAY_STORE_URL || 'https://play.google.com/store/apps/details?id=com.hollister.securestart';

// Note authheaderkeys and proxyauthheaderkeys should always match
export const proxyAuthHeaderKeys = ['proxyUid', 'proxyAuthorization'];

export const SIGN_IN_URL = "/signin";

export const GET_PATIENT_DETAILS = '/provider/patients/';
export const TRACK_PATIENT_AS_VIEWED = '/provider/patients/:id/track_patient_as_viewed';

export const UPDATE_SALESFORCE_ACCOUNT_INFO_CHECKBOX = 'provider/patient/slaesforce_sync?user_procedure_id=:user_procedure_id'

export const GET_PATIENT_TASKS = "/v2/tasks/all_tasks";

export const GET_OPTIONAL_TASKS = "/v2/tasks/get_optional_tasks";

export const ASSIGN_OPTIONAL_TASKS = "/v2/tasks/assign_optional_tasks";

export const GET_PATIENT_QUESTIONS = "/v2/question";
export const GET_PATIENT_CONVERSATIONS = "/conversations";
export const GET_CONVERSATION_MESSAGES = "/conversations/:id/messages";
export const START_CONVERSATION = "/conversations";
export const RESOLVE_CONVERSATION = "/conversations/:id/resolve";
export const SEND_MESSAGE = "/messages";

export const GET_PATIENT_APPOINTMENTS = "/v2/events";

export const GET_PROVIDER_SEARCH_RESULTS = "/provider/patients/search";

export const GET_APPOINTMENTS_FOR_DATE = "/provider/events/appointments_search";

export const GET_APPOINTMENTS_WITHIN_RANGE =
  "/provider/events/appointment_dates_search_within_range";

export const GET_TELEMEDICINE_APPOINTMENT_ACCESS_TOKEN =
  "/telemedicine_appointment_rooms/:sid/access_token";

export const GET_PROVIDER_FILTERS = "/v2/provider/filters";

export const GET_PATIENT_NOTIFICATIONS = "/v2/patient_notifications/";

export const GET_PROVIDER_PATIENTS_IN_RANGE = "/provider/patients_within_range";

export const PUT_UPDATE_EVENT = "v2/events/";

export const POST_CREATE_APPOINTMENT = "v2/events/create_appointment";

export const DELETE_APPOINTMENT = "v2/events/:id";
export const SKIP_APPOINTMENT = "v2/events/skip/:id";

export const DELETE_TASK = "v2/tasks/delete";

export const DELETE_ALL_RECURRING_TASKS = "v2/tasks/delete_all_recurring_tasks";

export const TASK_DETAILS = "v2/tasks/all_recurring/";
// SURVEYS
export const GET_SURVEYS = "v2/survey";

export const POST_SUBMIT_SURVEY_FEEDBACK = "v2/event/expected_answers/submit";

// EHR MEssages
export const GET_EHR_MESSAGES = "ehr/messages/:stage";
export const GET_SELECTED_MESSAGE = "ehr/:id";
export const GET_EHR_PROCEDURE_PROVIDERS_AND_LOCATIONS =
  "ehr/procedures_providers_and_locations";
export const UPDATE_EHR_RECORD = "ehr/update_record";
export const DISCARD_EHR_RECORD = "ehr/discard_ehr_message";
export const DISCARD_EHR_PROCEDURE = "ehr/discard_ehr_procedure";
export const GET_EHR_ACTIVITIES = "ehr/activities_count";
export const SEND_EHR_INVITES = "ehr/invite_patient";

// User Profile
export const GET_USER_INFO = "/user/info";
export const UPDATE_USER_INFO = "/user/update/";
export const UPDATE_USER_PROFILE_PICTURE = "user/update_profile_photo_url";
export const GET_USER_NOTIFICATIONS = "/notifications";
export const GET_UNREAD_USER_NOTIFICATIONS_COUNT =
  "/notifications/unread_count";
export const POST_READ_NOTIFICATIONS = "/user/notification_read";
export const UPDATE_USER_EMAIL = "/user/email/";
export const GET_CURRENT_USER_INFO = "user/current_user_info";

export const DELETE_PATIENT = "/provider/patients/:id";

export const POST_PROVIDER_CREATE_TASK = "/v2/tasks/provider_create";

export const GET_QUESTION_DETAILS = "/v2/provider/questions/";

// Documents
export const AUTHORIZE_URL = "/media_resources/authorize_url/";

export const ADD_NEW_PATIENT = "/provider/patients";

export const GET_DEPARTMENT_PROVIDERS = "department/providers";
export const GET_DEPARTMENT_ONLY_PROVIDERS = "department/only_providers";
export const GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS =
  "department/only_general_cardiologists";

export const GET_DEPARTMENT_PROCEDURES = "department/procedures";

export const GET_COOKIE_CONSENT_TEXT = 'patient/sign_ups/cookies_consent';
export const POST_COOKIE_CONSENT_PROVIDED = '/user_cookie_consent_logs';
export const GET_LOCATION_PROVIDERS = 'patient/sign_ups/location_providers';
export const GET_FLOW_ID = 'user_verifications/flow_id';
export const GET_MODIFIER = 'patient/sign_ups/get_modifier';
export const GET_FACILITY = 'patient/sign_ups/get_location';
export const GENERATE_OTP = 'patient/sign_ups/request_otp';
export const VERIFY_OTP = 'patient/sign_ups/verify_otp';
export const VALIDATE_ZIP_CODE = 'locations/valid_zipcode';
export const CREATE_PATIENT = 'patients';
export const SEND_TEMP_DATA = 'patient/sign_ups/mobile_sign_up';
export const SAVE_PROCEDURE = '/v2/user_procedures';

export const GET_PROCEDURE_LOCATIONS = 'v2/procedure/locations';

export const GET_PROCEDURE_PROVIDERS = "v2/procedure/providers";

export const GET_PROCEDURE_MODIFIERS = "v2/procedure/modifiers";
export const UPDATE_PROCEDURE_MODIFIERS = "v2/procedure/update_modifiers/:id";
export const UPDATE_PROCEDURE_LOCATION = "v2/procedure/update_location/:id";
export const DELETE_USER = '/provider/patients/:id/delete';
export const REVOKE_USER = '/provider/patients/:id/revoke';

export const GET_PROCEDURE_MEDTRONIC_MENTORS =
  "v2/procedure/only_patient_advocates";

export const SUBMIT_ANSWER = "v2/answer/";

// Patient Search
export const PATIENT_SEARCH = "/provider/patients/search/";
export const SAVE_SEARCH_FILTER =
  "/v2/provider/custom_filters/create_with_parent_filters";
export const UPDATE_SEARCH_FILTER = "/v2/provider/custom_filters/:id";
export const DELETE_SEARCH_FILTER = "/v2/provider/custom_filters/:id";

export const FORGOT_PASSWORD = "user/forgot-password";

export const CHANGE_PASSWORD = "/user/password";

export const CONFIRM_PASSWORD = '/user/confirm_password';

// Manage Patient Advocates
export const GET_ALL_PATIENT_ADVOCATE = "/manage_patient_advocates";
export const GET_ALL_PATIENTS = "/manage_patient_advocates/:id/patients";
export const REASSIGN_PATIENT = "/provider/transfer";

export const GET_LOCATION = "https://maps.googleapis.com/maps/api/geocode/json";
export const GET_ALL_LOCATIONS = "/locations/all_locations";
export const GET_ALL_PROCEDURES_MODIFIERS ='/patient/sign_ups/get_all_modifiers';
export const GET_TIMEZONE =
  "https://maps.googleapis.com/maps/api/timezone/json";

export const GET_LOCATION_TIMEZONE = "/locations/get_zip_location";

export const urlGenerator = (literal, ...substitutes) => {
  let urlStr = "";
  for (let i = 0; i < literal.length; i += 1) {
    if (literal[i] !== undefined) {
      urlStr += literal[i];
    }
    if (substitutes[i] !== undefined) {
      urlStr += substitutes[i];
    }
  }
  return urlStr;
};

export const COPY_RIGHT_YEAR = "2018";

export const GET_DOWNLOAD_LINK = "media_resources/download_link/?id=";

// Notify

export const POST_PUSH_NOTIFICATION = "/provider/send_push_notification";
export const POST_SMS_NOTIFICATION = "/provider/send_sms_notification";

// Connect

export const POST_CREATE_NOTE = "/connect/notes";
export const PUT_UPDATE_NOTE = "/connect/notes/";
export const PUT_UPDATE_COMMENTS = "/connect/comments/";

export const ADD_NEW_PATIENT_PROCEDURE = "/v2/procedure/select";

// Procedure Select

export const SWITCH_ACTIVE_PROCEDURE = "/v2/procedure/change_active_procedure";

export const GET_PATIENT_PROCEDURES = "/v2/procedure/patient_procedures";

// Patient Dashboard

export const GET_ALL_TASKS = "/v2/tasks/all";
export const POST_MARK_TASK_COMPLETE = "/v2/tasks/complete/";
export const POST_CREATE_TASK = "/v2/tasks/create";

export const GET_ALL_FAQ_QUESTIONS_METADATA = "/v2/question/tags";
export const GET_ALL_FAQ_QUESTIONS = "v2/question/all";
export const GET_PATIENT_QUESTION_DETAILS = "v2/question/";
export const POST_ANSWER_REVIEW = "/v2/answer/add_review";
export const POST_ASK_QUESTION = "v2/question/ask";
export const PUT_EDIT_QUESTION = "v2/question/";
export const DO_DELETE_QUESTION = "v2/question/delete/";
export const GET_ALL_MY_QUESTIONS = "/v2/question/my_questions";

export const GET_CAREGIVERS = "/patient/caregivers";
export const CREATE_CAREGIVER = "/caregivers";
export const REMOVE_CAREGIVER = "/caregivers/";

export const DELETE_COMPOSITE_PROCEDURE = "v2/procedure/user_procedures";

export const SIGNUP_URL = "patient_auth/";
export const GET_VERIFICATION_CODE =
  "phone_verification/request_phone_verification";
export const VERIFY_CODE = "phone_verification/verify_code";
export const GET_APPOINTMENTS = "/v2/events"; // THIS API CALL HAS DIFFERENT PARAMS FOR PATIENT

export const SEARCH_QUESTIONS = "question/v2/search";

export const CREATE_QUESTION = "/v2/question";

export const PUT_UPDATE_NOTIFICATION_REMINDER = "system_reminder/update/";

// Resources
export const GET_RESOURCES_METADATA = "/v2/resources/";

export const GET_RESOURCES_DETAILS = "v2/resources/display";

export const VERIFY_INVITE_CODE = "invite_code/exists";

export const GET_ALL_CAREGIVERS = "/provider/patient/:id/caregivers";
export const ADD_CAREGIVER = "/provider/patient/:id/caregivers";
export const DELETE_CAREGIVER = "/provider/patient/:id/caregivers";

export const UPDATE_PATIENT_NEEDS_ATTENTION =
  "/provider/patients/needs-attention";

export const GET_PATIENT_NOT_MOVING_FORWARD_REASONS = '/provider/patients/not-moving-forward-reasons';
export const UPDATE_NOT_MOVING_FORWARD = '/provider/patients/:id/update-not-moving-forward';

export const RESEND_INVITE = "/provider/patients/:id/resend-invite";

export const UPDATE_PATIENT_INFO = "/provider/patients/:id";

export const SUBMIT_RESOLUTION = "/internal_resolutions";

export const UPDATE_RESOLUTION = "/internal_resolutions/";

export const SET_PASSWORD = "/user_auth/password";

// Surveys
export const GET_DEPARTMENT_SURVEYS = "/surveys";

export const CREATE_OUTCOMES_URL = "/outcomes_url";

export const GET_PROCEDURE_MANAGERS = "/procedure_managers";
export const ADD_PROCEDURE_MANAGERS = "/procedure_managers";
export const REMOVE_PROCEDURE_MANAGERS = "/procedure_managers/:id";

export const GET_USER_CONSENT_TEXT = "consent_forms";

export const POST_USER_CONSENT = "consent_forms";

export const POST_DECLINE_USER_CONSENT = "consent_forms/decline";

export const POST_REQUEST_CALLBACK = "v2/question/request_callback";

export const ASK_PENDING_QUESTION = "user_questions/";

export const DELETE_USER_ACCOUNT = "user/delete";

export const GET_PATIENTS_FOR_CAREGIVER = "caregiver/patients";

export const POST_CAREGIVER_MASQUERADE = "caregiver/masquerade/start";

export const GET_PASSIVE_MODE_METADATA = "passive_mode/get_metadata";

export const GET_PASSIVE_MODE_CONTENT = "notifications/interactive";

export const POST_TASK_RESPONSE = "/notifications/response";

export const PUT_UPDATE_TASK_ATTACHMENTS = "v2/tasks/create_update_attachments";

export const GET_DOWNLOAD_DATA_REQUEST = "/data_download_request";

export const POST_DOWNLOAD_DATA_REQUEST = "/data_download_request";

export const SHOW_DOWNLOAD_DATA_REQUEST = "/data_download_request/show";

export const GET_DOWNLOAD_DATA_FILE = "data_download_request/download";

export const POST_DISCARD_PROCEDURE = "ehr/discard_scheduled_case";

export const PUT_TERMINATE_TRIAL = "v2/procedure/end_procedure";

export const VERIFY_LOGIN_CODE = "logins/verify_passive_mode";

export const INITIATE_LOGIN_WITH_OTP = "logins/initiate_passive_mode";

export const GET_DEPARTMENT_SUPPORTED_KEYS = "department/supported_keys";

export const VALIDATE_EMAIL_UNIQUNESS = 'user/is_email_unique'

export const VALIDATE_PHONE_UNIQUNESS = 'user/is_phone_unique'

export const SHARE_OUTCOMES_REPORT = "/share_outcomes";
export const GET_SHARED_OUTCOMES_REPORTS_HISTORY = "/share_outcomes";

export const SHOW_SHARE_OUTCOMES_REQUEST = "share_outcomes/show";

export const GET_SHARE_OUTCOMES_REPORT_FILE = "share_outcomes/download";

export const XEALTH_V1_PROGRAM = "xealth/v1/programs";

// Patient Details Profile
export const GET_OSTOMY_INFO =
  "/user_procedure/:procedure_id/supported_attribute_values?category_type=my_ostomy";

export const GET_CARE_TEAM_INFO =
  "/user_procedure/:procedure_id/supported_attribute_values?category_type=my_care_team";

export const GET_PERSONAL_INFO = "/v2/my_info?user_procedure_id=:procedure_id";

export const GET_SEARCH_CATEGORY = 'provider/patients/search_category';

//User Admin
export const GET_ALL_USERS_OF_THE_DEPARTMENT = "/users";
export const ADD_NEW_PROVIDER = "/users";
export const UPDATE_PROVIDER_INFO = '/users/:id';
export const GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER = "/performers"
export const CHANGE_PROVIDER_ACCOUNT_STATUS = "users"


export const GET_ALL_LOCATIONS_OF_THE_DEPARTMENT = "/locations";
export const ADD_NEW_LOCATION = "/locations";
export const UPDATE_LOCATION = "/locations/:id";
export const GET_TIMEZONES = "/timezones"
export const UPLOAD_FILE = "/locations/upload_file"
