import React from "react";
import Stack from "@mui/material/Stack";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import DatePicker from "./../../../components/StyleGuideComponents/DatePicker";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import { patientRegisterReducer} from "./../reducers";
import { privacyNoticeReducer } from "../../../common/authReducers";
import validate from "common/validator";
import { procedureDatesValidation } from "./../validators";
import { setSignUpData } from "./../action";
import { PROCEDURE_DETAILS_VIEW } from "./../constants";
import { withRouter } from 'react-router';

class ProcedureDatesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stomaMeasuringAppt: props.stomaMeasuringAppt || null,
      surgeryDate: props.surgeryDate || null,
      errors: []
    };
  }

  componentWillUnmount(){
    this.props.setSignUpDataInStore(this.state)
  }

  componentWillReceiveProps = (nextProps) => {
    const formData = {};
    Object.keys(nextProps).forEach((key) => {
      if (key in this.state) {

        if (nextProps[key] != this.state[key]) {
          formData[key] = nextProps[key];
        }
      }
    });
    if (Object.keys(formData).length) {
      this.setState({ ...formData });
    }
  };

  handleChange = (name, value) => {
    let errors = this.state.errors
    delete errors[name]
    this.setState({ [name]: value,errors:errors },()=>this.props.setSignUpDataInStore(this.state));
  };

  handleDateChange = (key, value) => {

    this.setState({ [key]: value });
  };

  onValidationFailure = (errors) => {
    this.setState({ errors: errors });
  };

  onNextValidationSuccess = () => {
    this.props.setSignUpDataInStore(this.state, this.props.nextPage, PROCEDURE_DETAILS_VIEW);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    validate(
      procedureDatesValidation,
      this.state,
      this.onValidationFailure,
      this.onNextValidationSuccess
    );
  }

  render() {
    return (
      <div style={{paddingBottom:"-6%"}}>
        <form type="post">
          <div>
            <Stack sx={{zIndex:"0"}} spacing={"24px"}>
              <DatePicker
                name="stomaMeasuringAppt"
                value={this.state.stomaMeasuringAppt}
                label="Stoma Measuring Appointment"
                error={"stomaMeasuringAppt" in this.state.errors}
                errorText={
                  "stomaMeasuringAppt" in this.state.errors
                    ? this.state.errors.stomaMeasuringAppt
                    : ""
                }
                onChange={ this.handleChange }
              />

              <DatePicker
                name="surgeryDate"
                value={this.state.surgeryDate}
                label="Surgery Date"
                error={"surgeryDate" in this.state.errors}
                errorText={
                  "surgeryDate" in this.state.errors
                    ? this.state.errors.surgeryDate
                    : ""
                }
                onChange={ this.handleChange }
              />

            </Stack>
            <div className="continue-button-for-form">
              <PrimaryButton
                fullWidth
                text={this.props.nextButtonText}
                onClick={this.handleSubmit}
              />
              </div>
          </div>
        </form>

      </div>
    );
  }
}

ProcedureDatesView.propTypes = {
  stomaMeasuringAppt: PropTypes.object,
  surgeryDate: PropTypes.object
};

const mapStateToProps = (state) => ({
  stomaMeasuringAppt:
    state.patientRegister.patientRegisterReducer.stomaMeasuringAppt,
  surgeryDate: state.patientRegister.patientRegisterReducer.surgeryDate
});

const mapDispatchToProps = (dispatch) => ({
  setSignUpDataInStore: (data, nextPage, page) =>
    dispatch(setSignUpData(data, nextPage, page))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "patientRegister",
  reducer: combineReducers({
    patientRegisterReducer,
    privacyNoticeReducer
  }),
});

export default compose(withReducer, withRouter,withConnect)(ProcedureDatesView);

