import React from 'react';
import {dateTimeStringToMMSDateFormat} from "utils/dateUtils";
import Attachments from "containers/Attachments";
import "./messageListCard.scss";
import Linkify from 'react-linkify';

class MessagesListCard extends React.Component {
  render() {
    return (
      <div className="message-list-row col-xs-10">
        <div className={`row content ${this.props.item.type === "Patient" ? "patient" : "provider"}`}>
          <div className="font-weight-300">
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="_blank" rel="noreferrer" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {this.props.item.body}
            </Linkify>
          </div>
          <Attachments mediaResources={this.props.item.mediaResources} isAdded={false} notShowAttachmentsCount={true}/>
          <div className="margin-top-5 font-size-sm">
            <span className="font-weight-300">{this.props.item.sentBy.fullName}</span>
            <span className="dot"></span>
            <span className="sent-on">Sent On: </span>
            <span className="date">{ dateTimeStringToMMSDateFormat(this.props.item.createdAt) }</span>
          </div>
        </div>  
        <br />
      </div>
    );
  }
}

export default MessagesListCard;
