import React from "react";
import Stack from "@mui/material/Stack";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import MUIAutocompleteSelect from "../../../components/SelectV2/MUIAutocompleteSelect";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import { patientRegisterReducer} from "./../reducers";
import { privacyNoticeReducer } from "../../../common/authReducers";
import validate from "common/validator";
import { procedureDetailsValidation } from "./../validators";
import { setSignUpData } from "./../action";
import DisclaimerView from "./DisclaimerView";
import { SERVICE_AGREEMENT_VIEW } from "./../constants";
import { withRouter } from "react-router";
import { createFilterOptions } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import strings from "../../../strings";
import {
  getAllLocationsRequest,
  getAllProceduresModifiersRequest,
} from "common/actions";

class ProcedureDetailsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modifierIdentifier: { label: "", value: "" },
      modifierIdentifierModal: false,
      facilityId: { label: "", value: "" },
      educationPreferenceModal: false,
      educationPreference: { label: "", value: false },
      errors: [],
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.allModifiers.length) {
      let modifierIdentifierParam = nextProps.allModifiers.find((modifier) => {
        return (
          modifier.modifierIdentifier === this.props.match.params.procedure
        );
      });
      if (modifierIdentifierParam) {
        this.setState({
          modifierIdentifier: {
            label: modifierIdentifierParam.value,
            value: modifierIdentifierParam.modifierIdentifier,
          },
        });
      }
    }
    if (nextProps.allLocations.length) {
      let location = nextProps.allLocations.find((location) => {
        return location.id === this.props.match.params.facility_name;
      });
      if (location) {
        this.setState({
          facilityId: { label: location.name, value: location.id },
        });
      }
    }

    const formData = {};
    Object.keys(nextProps).forEach((key) => {
      if (key in this.state) {
        if (nextProps[key] != this.state[key]) {
          formData[key] = nextProps[key];
        }
      }
    });
    if (Object.keys(formData).length) {
      this.setState({ ...formData });
    }
  };

  componentDidMount = () => {
    if(this.props.allLocations.length <= 0) this.props.getAllLocations()
    if(this.props.allModifiers.length <= 0) this.props.getAllProceduresModifiers()
    const formData = {};
    Object.keys(this.props).forEach((key) => {
      if (key in this.state) {
        if (this.props[key] != this.state[key]) {
          formData[key] = this.props[key];
        }
      }
    });
    if (Object.keys(formData).length) {
      this.setState({ ...formData });
    }
    if (this.props.allModifiers.length) {
      let modifierIdentifierParam = this.props.allModifiers.find((modifier) => {
        return (
          modifier.modifierIdentifier === this.props.match.params.procedure
        );
      });
      if (modifierIdentifierParam) {
        this.setState({
          modifierIdentifier: {
            label: modifierIdentifierParam.value,
            value: modifierIdentifierParam.modifierIdentifier,
          },
        });
      }
    }
  };

  componentWillUnmount(){
    this.props.setSignUpDataInStore(this.state)
  }

  handleChange = (name, value) => {
    let errors = this.state.errors;
    delete errors[name];
    this.setState({ [name]: value, errors: errors });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    validate(
      procedureDetailsValidation,
      this.state,
      this.onValidationFailure,
      this.onNextValidationSuccess
    );
  };

  checkIfButtonEnabled = () => {
    return (
      this.state.modifierIdentifier &&
      this.state.facilityId &&
      this.state.modifierIdentifier.value &&
      this.state.facilityId.value
    );
  };

  onValidationFailure = (errors) => {
    this.setState({ errors: errors });
  };

  onNextValidationSuccess = () => {
    this.props.setSignUpDataInStore(
      this.state,
      this.props.nextPage,
      SERVICE_AGREEMENT_VIEW
    );
  };

  onInfoModalClose = () => {
    this.setState({ modifierIdentifierModal: false, educationPreferenceModal: false });
  };

  facilityOptions = () =>{
    let options = [];
    if(this.props.allLocations.length){
        let first_element = this.props.allLocations.find(location => location.code ==='NOTSUREFC');
        let last_element = this.props.allLocations.find(location => location.code ==='OTHERFC');
        options.push({label: first_element.name, value: first_element.id, city: first_element.city, state: first_element.state});
        options.push({label: last_element.name, value: last_element.id, city: last_element.city, state: last_element.state})
    }
    return options
 }

 filterFacilityOptionsList = () =>{
    let _filterOptions = createFilterOptions({
        stringify: (option) =>
          option.label + " " + option.city + " " + option.state,
      })
      const localFilterOptions = (options, state) => {
        let results = [this.facilityOptions()[0], ..._filterOptions(options, state),this.facilityOptions()[1]];
        return results;
      };
      return localFilterOptions;
 }

  render() {
    return (
      <div style={{ paddingBottom: "-6%" }}>
        <DisclaimerView
          title={this.state.educationPreferenceModal ?  strings.EDUCATION_PREFERENCE_INFO_MODAL_HEADING : strings.PROCEDURE_INFO_MODAL_HEADING}
          body={<div dangerouslySetInnerHTML={{__html: this.state.educationPreferenceModal ?  strings.EDUCATION_PREFERENCE_INFO_MODAL_BODY : strings.PROCEDURE_INFO_MODAL_BODY}} />}
          primaryButtonText="Got it!"
          open={this.state.educationPreferenceModal || this.state.modifierIdentifierModal}
          onClose={this.onInfoModalClose}
          onAgree={this.onInfoModalClose}
        />
        <form type="post">
          <div> 
            <Stack sx={{ zIndex: "0" }} spacing={"24px"}>
              <div className="procedure-detail-input-wrapper">
                <div className="procedure-details-label">
                  Search by Facility Name or city and state
                  <span className="app-red">
                    *<></>
                  </span>
                </div>
                <MUIAutocompleteSelect
                  multiline
                  getOptionLabel={ 
                    option => { 
                       return option.label 
                     
                    } 
                  }
                  isOptionEqualToValue={(option, value) => { if(option){ return option.value || null || undefined === value}}}
                  placeholder={"Select your facility"}
                  filterOptions={
                    this.filterFacilityOptionsList()
                }
                  required={true}
                  name="facilityId"
                  options={
                    this.props.allLocations.length && this.props.allLocations.filter(location => location.code!=='NOTSUREFC' && location.code!=='OTHERFC').map((location) => {
                    return {
                        label: location.name,
                        value: location.id,
                        city: location.city,
                        state: location.state
                        }
                  })
                  }
                  value={this.state.facilityId}
                  onChange={this.handleChange}
                  autoCompleteTheOptions
                />
              </div>
              <div className="procedure-detail-input-wrapper">
                <div className="procedure-details-label">
                  Procedure Details<span className="app-red">*</span>
                  <InfoOutlinedIcon sx={{height: "25px",width: "25px"}} onClick={()=>this.setState({modifierIdentifierModal: true})} />
                </div>
                <MUIAutocompleteSelect
                  getOptionLabel={ 
                    option => { 
                       return option.label 
                    
                    } 
                  }
                  isOptionEqualToValue={(option, value) => option.value || null === value.value}
                  required={true}
                  placeholder="Select your procedure"
                  name="modifierIdentifier"
                  options={
                    this.props.allModifiers.length && this.props.allModifiers.map((modifier) => {
                    return {
                      label: modifier.value,
                      value: modifier.modifierIdentifier,
                    };
                  })
                  }
                  value={this.state.modifierIdentifier}
                  onChange={this.handleChange}
                />
              </div>
              <div className="procedure-detail-input-wrapper">
                <div className="procedure-details-label">
                  Education Preferences
                  <InfoOutlinedIcon sx={{height: "25px",width: "25px"}} onClick={()=>this.setState({educationPreferenceModal: true})} />
                </div>
                <MUIAutocompleteSelect
                  placeholder="Select your education preference"
                  required={true}
                  isOptionEqualToValue={(option, value) => option.value || null === value.value}
                  name="educationPreference"
                  options={[
                    { label: "View for my procedure only", value: false },
                    { label: "View for all stoma types", value: true },
                  ]}
                  value={this.state.educationPreference}
                  onChange={this.handleChange}
                />
              </div>
            </Stack>
            <div className="continue-button-for-form">
              <PrimaryButton
                disabled={!this.checkIfButtonEnabled()}
                fullWidth
                text={this.props.nextButtonText}
                onClick={this.handleSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ProcedureDetailsView.propTypes = {
  modifierIdentifier: PropTypes.object,
  facilityId: PropTypes.object,
};

const mapStateToProps = (state) => ({
  modifierIdentifier:
    state.patientRegister.patientRegisterReducer.modifierIdentifier,
  facilityId: state.patientRegister.patientRegisterReducer.facilityId,
  allLocations: state.register.allLocationsReducer.allLocations,
  allModifiers:
    state.register.allProceduresModifiersReducer.allModifiers,
  educationPreference: state.patientRegister.patientRegisterReducer.educationPreference
});

const mapDispatchToProps = (dispatch) => ({
  setSignUpDataInStore: (data, nextPage, page) =>
    dispatch(setSignUpData(data, nextPage, page)),
  getAllLocations: () => dispatch(getAllLocationsRequest()),
  getAllProceduresModifiers: () => dispatch(getAllProceduresModifiersRequest()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "patientRegister",
  reducer: combineReducers({
    patientRegisterReducer,
    privacyNoticeReducer
  }),
});

export default compose(
  withReducer,
  withRouter,
  withConnect
)(ProcedureDetailsView);
