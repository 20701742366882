import React from "react";
import "../patientRegister.scss";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";

import { createFilterOptions } from "@mui/material";
import MUIAutocompleteSelect from "../../../components/SelectV2/MUIAutocompleteSelect";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import { patientRegisterReducer } from "../reducers";
import PrimaryButton from "../../../components/StyleGuideComponents/PrimaryButton";
import { generateOtp, verifyOtp, saveProcedure } from "../action";
import validate from "common/validator";
import { withRouter } from 'react-router';

import { setOtpInState, setSignUpDataForExistingUser} from './../action'
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { getItemFromStorage } from "services/storage";
import isEmpty from "lodash/isEmpty";
import DatePicker from "./../../../components/StyleGuideComponents/DatePicker";
import { DATE_FORMAT } from "../../../constants";

import { SUCCESS_VIEW } from "./../constants";

import {
  signInUser,
} from "common/authThunkActions";
import {
  getAllLocationsRequest,
  getAllProceduresModifiersRequest,
} from "common/actions";
import {
  allLocationsReducer,
  allProceduresModifiersReducer,
} from "common/reducer";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DisclaimerView from "./DisclaimerView";
import strings from "../../../strings";

class LoggedInUserProcedureView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flowId: props.flowId || null,
      flowType: props.flowType || "sign_up",
      firstName: props.firstName || "",
      emailOtp: props.emailOtp || "",
      email: props.email || "",
      errors: [],
      phone: props.phone || "",
      zipCode: props.zipCode || "",
      emailOrMobileNumber: "",
      password: "",
      isSignedIn: props.isSignedIn,

      stomaMeasuringAppt: props.stomaMeasuringAppt || null,
      surgeryDate: props.surgeryDate || null,
      procedure: props.procedure || "",
      facilityCode: props.facilityCode || props.match.params.facility_name,
      facilityName: props.facilityName || "",

      modifierIdentifier: { label: "", value: "" },
      facilityId: { label: "", value: "" },
      modifierIdentifierModal: false

    };
  }

  phoneRule = [
    { rule: "isPhone", message: "Mobile Number or Email is invalid" },
    { rule: "isRequired", message: "Mobile Number or Email is required" },
  ];

  emailRule = [
    { rule: "isEmail", message: "Mobile Number or Email is invalid" },
    { rule: "isRequired", message: "Mobile Number or Email is required" },
  ];

  validationConfig = {
    fields: ["stomaMeasuringAppt", "surgeryDate"],
    rules: {
      stomaMeasuringAppt: [
        { rule: "isValidDate", message: `Appointment Date is Invalid (Format: ${DATE_FORMAT.toUpperCase()})` },
      ],
      surgeryDate: [
        { rule: "isValidDate", message: `Surgery Date is Invalid (Format: ${DATE_FORMAT.toUpperCase()})` },
      ],
    },
  };

  handleChange = (name, value) => {
    let errors = this.state.errors
    delete errors[name]

this.setState({ [name]: value,errors:errors });
  };

  onInfoModalClose = () => {
    this.setState({ modifierIdentifierModal: false });
  };

  submitForm = (e) => {
    e.preventDefault();
    validate(
      this.validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  };

  onFormValidationSuccess = () => {
    const params = {
      facility_id: this.state.facilityId.value,
      procedure_modifier: this.state.modifierIdentifier.value,
      procedure_date: this.state.surgeryDate,
      stoma_marking_date: this.state.stomaMeasuringAppointment,
      flow_type: this.state.flowType,
      flow_id: this.state.flowId
  };

  this.props.saveUserProcedure(params, this.props.nextPage, SUCCESS_VIEW);
    
  }

  onFormValidationFailure = errors => {
    this.setState({ errors });
  }

  componentDidMount = () => {
    this.props.getAllLocations();
    this.props.getAllProceduresModifiers(this.props.changeloggedInProcedureDetailsTitle);
  };

  facilityOptions = () =>{
    let options = [];
    if(this.props.allLocations.length){
        let first_element = this.props.allLocations.find(location => location.code ==='NOTSUREFC');
        let last_element = this.props.allLocations.find(location => location.code ==='OTHERFC');
        options.push({label: first_element.name, value: first_element.id, city: first_element.city, state: first_element.state});
        options.push({label: last_element.name, value: last_element.id, city: last_element.city, state: last_element.state})
    }
    return options
 }

 filterFacilityOptionsList = () =>{
    let _filterOptions = createFilterOptions({
        stringify: (option) =>
          option.label + " " + option.city + " " + option.state,
      })
      const localFilterOptions = (options, state) => {
        let results = [this.facilityOptions()[0], ..._filterOptions(options, state),this.facilityOptions()[1]];
        return results;
      };
      return localFilterOptions;
 }

  componentWillReceiveProps(nextProps) {

    if (nextProps.allModifiers.length) {
      let modifierIdentifier = nextProps.allModifiers.find((modifier) => {
        return (
          modifier.modifierIdentifier === this.props.match.params.procedure
        );
      });
      if (modifierIdentifier) {
        this.setState({
          modifierIdentifier: {
            label: modifierIdentifier.value,
            value: modifierIdentifier.modifierIdentifier,
          },
        });
      }
    }
    if(nextProps.isSignedIn != this.state.isSignedIn)
    {
      this.setState({ isSignedIn: nextProps.isSignedIn });
    }
    const formData = {};
    Object.keys(nextProps).forEach((key) => {
      if (key in this.state) {

        if (nextProps[key] != this.state[key]) {
          formData[key] = nextProps[key];
        }
      }
    });
    if (Object.keys(formData).length) {
      this.setState({ ...formData });
    }
  }

  onValidationFailure = (errors) => {
    this.setState({ errors: errors });
  };

  componentDidUpdate = () => {
    if(this.state.isSignedIn && this.state.page == 9){
      this.props.nextPage();
    }
  }

  render() {
    if(!this.state.isSignedIn){
      this.props.prevPage();
    }
    if(this.props.currentUser.role !== 'Patient' || !this.props.allModifiers.length){
      return (<></>)
    }
    return (
      <div className="procedure-screen-wrapper no-padding">
        <DisclaimerView
          title={strings.PROCEDURE_INFO_MODAL_HEADING}
          body={<div dangerouslySetInnerHTML={{__html: strings.PROCEDURE_INFO_MODAL_BODY}} />}
          primaryButtonText="Got it!"
          open={this.state.modifierIdentifierModal}
          onClose={this.onInfoModalClose}
          onAgree={this.onInfoModalClose}
        />
        <Container maxWidth="lg">
          <Box className="procedure-page-container">
            <div className="login-form">
            <form type="post">
          <div>
            <Stack sx={{zIndex:"0"}} spacing={"24px"}>
              <div className="procedure-detail-input-wrapper">
                <div className="procedure-details-label">
                  Search by Facility Name or city and state
                  <span className="app-red">
                    *<></>
                  </span>
                </div>
                <MUIAutocompleteSelect
                  isOptionEqualToValue={(option, value) => option.value || null === value}
                  placeholder={"Select your facility"}
                  filterOptions={
                    this.filterFacilityOptionsList()
                  }
                  required={true}
                  name="facilityId"
                  multiline
                  options={
                    this.props.allLocations.length ? this.props.allLocations.filter(location => location.code!=='NOTSUREFC' && location.code!=='OTHERFC').map((location) => {
                      return {
                          label: location.name,
                          value: location.id,
                          city: location.city,
                          state: location.state
                          }
                    }):[{label:'',value:''}]
                  }
                  value={this.state.facilityId}
                  onChange={this.handleChange}
                  autoCompleteTheOptions
                />
              </div>
              <div className="procedure-detail-input-wrapper">
                <div className="procedure-details-label">
                  Procedure Details<span className="app-red">*</span>
                  <InfoOutlinedIcon sx={{height: "25px",width: "25px"}} onClick={()=>this.setState({modifierIdentifierModal: true})} />
                </div>
                <MUIAutocompleteSelect
                  isOptionEqualToValue={(option, value) => option.value || null === value}
                  required={true}
                  placeholder="Select your procedure"
                  name="modifierIdentifier"
                  options={
                    this.props.allModifiers.length ? this.props.allModifiers.map((modifier) => {
                      return {
                        label: modifier.value,
                        value: modifier.modifierIdentifier,
                      };
                    }):[{label:'',value:''}]
                  }
                  value={this.state.modifierIdentifier ? this.state.modifierIdentifier : ''}
                  onChange={this.handleChange}
                />
              </div>
              <DatePicker
                name="stomaMeasuringAppt"
                value={this.state.stomaMeasuringAppt}
                label="Stoma Measuring Appointment"
                error={"stomaMeasuringAppt" in this.state.errors}
                errorText={
                  "stomaMeasuringAppt" in this.state.errors
                    ? this.state.errors.stomaMeasuringAppt
                    : ""
                }
                onChange={ this.handleChange }
              />
              <DatePicker
                name="surgeryDate"
                value={this.state.surgeryDate}
                label="Surgery Date"
                error={"surgeryDate" in this.state.errors}
                errorText={
                  "surgeryDate" in this.state.errors
                    ? this.state.errors.surgeryDate
                    : ""
                }
                onChange={ this.handleChange }
              />
            </Stack>
          </div>
        </form>

            </div>
            <div className="procedure-input-continue-button">
            <PrimaryButton onClick={this.submitForm} 
              disabled={!(this.state.modifierIdentifier && this.state.facilityId && this.state.modifierIdentifier.value && this.state.facilityId) } 
              text="Submit"  />
            </div>
          </Box>
        </Container>
      </div>

    );
  }
}

LoggedInUserProcedureView.propTypes = {
  isLoading: PropTypes.bool,
  firstName: PropTypes.string,
  email: PropTypes.string,
  stomaMeasuringAppt: PropTypes.object,
  surgeryDate: PropTypes.object,
  procedure: PropTypes.string,
  facilityName: PropTypes.string,
  modifierIdentifier: PropTypes.string,
  facilityCode: PropTypes.string,
  isSignedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  firstName: state.patientRegister.patientRegisterReducer.firstName,
  email: state.patientRegister.patientRegisterReducer.email,
  phone: state.patientRegister.patientRegisterReducer.phone,
  zipCode: state.patientRegister.patientRegisterReducer.zipCode,
  stomaMeasuringAppt:
  state.patientRegister.patientRegisterReducer.stomaMeasuringAppt,
  surgeryDate: state.patientRegister.patientRegisterReducer.surgeryDate,
  procedure: state.patientRegister.patientRegisterReducer.procedure,
  modifierIdentifier: state.patientRegister.patientRegisterReducer.modifierIdentifier,
  facilityName: state.patientRegister.patientRegisterReducer.facilityName,
  facilityCode: state.patientRegister.patientRegisterReducer.facilityCode,
  flowType: state.patientRegister.patientRegisterReducer.flowType,
  flowId:
    state.patientRegister.patientRegisterReducer.flowId,
  isSignedIn:
      state.currentUser.isSignedIn || !isEmpty(getItemFromStorage("token")),
  modifierIdentifier:
      state.patientRegister.patientRegisterReducer.modifierIdentifier,
  facilityId: state.patientRegister.patientRegisterReducer.facilityId,
  allLocations: state.patientRegister.allLocationsReducer.allLocations,
  allModifiers:
    state.patientRegister.allProceduresModifiersReducer.allModifiers,
  educationPreference: state.patientRegister.patientRegisterReducer.educationPreference,
  existingUser: state.patientRegister.patientRegisterReducer.existingUser,
  currentUser: state.currentUser.attributes
});

const mapDispatchToProps = (dispatch) => ({
  signInUser: (params) => dispatch(signInUser(params)),
  generateEmailOtp: (params, nextPage, redirect = true) =>
    dispatch(generateOtp(params, nextPage, redirect)),
  setOtpInReduxState: (params) =>
    dispatch(setOtpInState(params)),
  generatePhoneOtp: (params, nextPage) =>
    dispatch(generateOtp(params, nextPage)),
  verifyEmailOtp: (params, nextPage) => dispatch(verifyOtp(params, nextPage)),
  setSignUpDataForExistingUser: (data) =>
    dispatch(setSignUpDataForExistingUser(data)),
  getAllLocations: () => dispatch(getAllLocationsRequest()),
  getAllProceduresModifiers: (callBack) => {dispatch(getAllProceduresModifiersRequest(callBack))},
  saveUserProcedure: (params, nextPage, page) =>
        dispatch(saveProcedure(params, nextPage, page)),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "patientRegister",
  reducer: combineReducers({
    patientRegisterReducer,
    allLocationsReducer,
    allProceduresModifiersReducer,
  }),
});

export default compose(withReducer, withRouter, withConnect)(LoggedInUserProcedureView);
