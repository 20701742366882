import React from "react";
import Stack from "@mui/material/Stack";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import InputTextField from "./../../../components/StyleGuideComponents/InputTextField";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import { patientRegisterReducer} from "./../reducers";
import { privacyNoticeReducer } from "../../../common/authReducers";
import validate from "common/validator";
import { addressViewValidation } from "./../validators";
import {setSignUpData} from "./../action";
import { PROCEDURE_DATES_VIEW } from "./../constants";
import { withRouter } from 'react-router';
import { validateZipCode } from "../../../services/patientDetails";
import { SET_IS_LOADING } from "./../constants";

class AddressView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addressLine1: props.addressLine1 || "",
      addressLine2: props.addressLine2 || "",
      city: props.city || "",
      zipCode: props.zipCode || "",
      errors: [],
    };
  }

  componentWillReceiveProps = (nextProps) => {

    const formData = {};
    Object.keys(nextProps).forEach((key) => {
      if (key in this.state) {

        if (nextProps[key] != this.state[key]) {
          formData[key] = nextProps[key];
        }
      }
    });
    if (Object.keys(formData).length) {
      this.setState({ ...formData });
    }
  };

  componentWillUnmount(){
    this.props.setSignUpDataInStore(this.state)
  }

  handleChange = (name, value) => {
    let errors = this.state.errors
    delete errors[name]
    if(name === 'zipCode') this.setState({ [name]: value.toUpperCase(),errors:errors });
    else this.setState({ [name]: value,errors:errors });

  };

  onValidationFailure = (errors) => {
    this.setState({ errors: errors });
  };

  onNextValidationSuccess = () => {
    this.props.setSignUpDataInStore(this.state, this.props.nextPage, PROCEDURE_DATES_VIEW);
  };

  checkIfButtonEnabled = ()  => {
    return this.state.addressLine1 && this.state.addressLine1.length && this.state.city && this.state.city.length && this.state.zipCode && this.state.zipCode.length;
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const initErrors = {};
    this.props.setIsLoading(true);
    try {
      const { data } = await validateZipCode(this.state.zipCode.trim().replaceAll('.','').replaceAll('-',''));
      this.props.setIsLoading(false);
      if (!data.valid) {
        initErrors.zipCode = "Postcode is invalid";
        this.props.setIsLoading(false);
      }
    } catch (error) {
      initErrors.zipCode = "Postcode is invalid";
      this.props.setIsLoading(false);
    }

    validate(
      addressViewValidation,
      this.state,
      this.onValidationFailure,
      this.onNextValidationSuccess,
      initErrors,
    );
  };

  render() {
    return (
      <div style={{paddingBottom:"-6%"}}>

        <form type="post">
          <div>
            <Stack sx={{zIndex:"0"}} spacing={"24px"}>

              <InputTextField
                id="addressLine1"
                value={this.state.addressLine1}
                label="Address Line 1"
                name="addressLine1"
                onChange={ this.handleChange }
                required
                error={"addressLine1" in this.state.errors}
                errorText={
                  "addressLine1" in this.state.errors
                    ? this.state.errors.addressLine1
                    : ""
                }
              />

              <InputTextField
                value={this.state.addressLine2}
                id="addressLine2"
                label="Address Line 2"
                name="addressLine2"
                onChange={ this.handleChange }
              />

              <InputTextField
                value={this.state.city}
                id="city"
                label="City"
                name="city"
                onChange={ this.handleChange }
                required
                error={"city" in this.state.errors}
                errorText={
                  "city" in this.state.errors ? this.state.errors.city : ""
                }
              />

              <InputTextField
                value={this.state.zipCode}
                id="zipCode"
                label="Postcode"
                name="zipCode"
                onChange={ this.handleChange }
                required
                maxLength={10}
                error={"zipCode" in this.state.errors}
                errorText={
                  "zipCode" in this.state.errors
                    ? this.state.errors.zipCode
                    : ""
                }
              />

            </Stack>
            <div className="continue-button-for-form">
              <PrimaryButton
              disabled={!this.checkIfButtonEnabled()}
                fullWidth
                text={this.props.nextButtonText}
                onClick={this.handleSubmit}
              />
              </div>
          </div>
        </form>

      </div>
    );
  }
}

AddressView.propTypes = {
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string
};

const mapStateToProps = (state) => ({
  addressLine1: state.patientRegister.patientRegisterReducer.addressLine1,
  addressLine2: state.patientRegister.patientRegisterReducer.addressLine2,
  city: state.patientRegister.patientRegisterReducer.city,
  state: state.patientRegister.patientRegisterReducer.state,
  zipCode: state.patientRegister.patientRegisterReducer.zipCode
});

const mapDispatchToProps = (dispatch) => ({
  setSignUpDataInStore: (data, nextPage, page) =>
    dispatch(setSignUpData(data, nextPage, page)),
  setIsLoading: (data)=>
    dispatch({type: SET_IS_LOADING, data})
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "patientRegister",
  reducer: combineReducers({
    patientRegisterReducer,
    privacyNoticeReducer
  }),
});

export default compose(withReducer, withRouter,withConnect)(AddressView);

