import * as React from "react";
import { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import "../../assets/stylesheets/_variables.scss";
import { IconButton } from "@material-ui/core";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { InputTextFieldTheme } from "./common/Themes";
import { InputBoxStyleGuide } from "./StyleGuideCSS";
import PhoneInput from "components/PhoneInput";
import InputMask from "react-input-mask";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import {PHONE_NUMBER_MASK, ZIP_CODE_MASK} from "../../constants";

export default function InputTextField(props) {

  const handleChange = (e) =>{
    props.onChange(props.name,e.target.value);
  }

  const errorIcon = {
    endAdornment: (
      <InputAdornment position="end">
        <ErrorOutlineIcon
          sx={{ color: `${InputBoxStyleGuide.colorAlertNegative}` }}
        />
      </InputAdornment>
    ),
  };

  const clearButton = {
    endAdornment: (
      <IconButton
        aria-label="toggle password visibility"
        onClick={ ()=>props.onChange(props.name,"") }
      >
        <CancelOutlinedIcon
          fontSize="small"
          sx={{ color: "#575558", height: "20px", width: "20px" }}
        />
      </IconButton>
    ),
  };

  return (
    <ThemeProvider theme={InputTextFieldTheme}>
      {props.type === "phone" ? (
        <InputMask
          mask={PHONE_NUMBER_MASK}
          maskChar=""
          value={props.value}
          onChange={handleChange}
        >
          {() => (
            <TextField
              sx={{
                ...props.sx,
                fontSize: "16px",
              }}
              error={props.error}
              id={props.id || "outlined-basic"}
              label={props.label || "Enter Text"}
              variant={props.variant || "outlined"}
              name={props.name || "text"}
              color="primary"
              value={props.value ? props.value : ""}
              fullWidth
              helperText={props.error && props.errorText ? props.errorText : ""}
              InputProps={
                !props.disabled
                  ? props.error
                    ? errorIcon
                    : props.value
                    ? clearButton
                    : {}
                  : null
              }
              inputProps={{
                maxLength: props.maxLength ? props.maxLength : 150,
              }}
              required={props.required}
              onChange={handleChange}
              size="normal"
            />
          )}
        </InputMask>
      ) : (
        <TextField
          sx={{
            ...props.sx,
            fontSize: "16px",
          }}
          error={props.error}
          id={props.id || "outlined-basic"}
          label={props.label || "Enter Text"}
          variant={props.variant || "outlined"}
          name={props.name || "text"}
          color="primary"
          value={props.value ? props.value : ""}
          fullWidth
          helperText={props.error && props.errorText ? props.errorText : ""}
          InputProps={
            !props.disabled
              ? props.error
                ? errorIcon
                : props.value
                ? clearButton
                : {}
              : null
          }
          inputProps={{ maxLength: props.maxLength ? props.maxLength : 150 }}
          required={props.required}
          onChange={handleChange}
          size="normal"
          disabled={props.disabled}
        />
      )}
    </ThemeProvider>
  );

  }
