import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {ThemeProvider} from "@mui/material/styles";
import {theme} from "./Theme";
import {InputBoxStyleGuide, passwordVisibilityIcon} from "./StyleGuideCSS";
import "./styleGuideComponents.scss";
// import { error } from "shelljs/src/common";

export default function Password(props) {

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <ThemeProvider theme={theme}>
            <Box>
                <div>
                    <FormControl
                        sx={{m: 1, width: "100%", margin: "0"}}
                        variant="outlined"
                    >
                        <InputLabel
                            required={props.required}
                            sx={{fontSize: "16px"}}
                            htmlFor="outlined-adornment-password"
                        >
                            {"Password"}
                        </InputLabel>

                        <OutlinedInput
                            error={props.error}
                            sx={{
                                ...props.sx,
                                fontSize: "16px",
                            }}
                            fullWidth
                            size="normal"
                            onFocus={
                                props.onFocus
                                    ? () => {
                                        props.onFocus();
                                    }
                                    : null
                            }
                            onBlur={
                                props.onBlur
                                    ? () => {
                                        props.onBlur();
                                    }
                                    : null
                            }
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            value={props.value}
                            onChange={props.onChange}
                            required={props.required}
                            endAdornment={
                                props.value && (
                                    <InputAdornment size="normal" position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={ togglePasswordVisibility }
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="normal"
                                        >
                                            {props.showPassword ? (
                                                <VisibilityOff
                                                    sx={{
                                                        height: passwordVisibilityIcon.height,
                                                        width: passwordVisibilityIcon.width,
                                                        color: passwordVisibilityIcon.color,
                                                    }}
                                                />
                                            ) : (
                                                <Visibility
                                                    sx={{
                                                        height: passwordVisibilityIcon.height,
                                                        width: passwordVisibilityIcon.width,
                                                        color: passwordVisibilityIcon.color,
                                                    }}
                                                />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }
                            label={props.password || "Password"}
                        />
                        <FormHelperText
                            id="outlined-weight-helper-text"
                            className="no-margin"
                        >
                            <p
                                style={{
                                    color: `${InputBoxStyleGuide.colorAlertNegative}`,
                                    fontSize: "12px",
                                }}
                            >
                                {props.errorText ? props.errorText : ""}
                            </p>
                        </FormHelperText>
                    </FormControl>
                </div>
            </Box>
        </ThemeProvider>
    );
}
