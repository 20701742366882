import "whatwg-fetch";
import axios, { axiosWithAuthAndCamelcase } from "./base";
import defaultAxios from 'axios';

import {
  GET_DEPARTMENT_PROCEDURES,
  GET_DEPARTMENT_PROVIDERS,
  SAVE_SEARCH_FILTER,
  UPDATE_SEARCH_FILTER,
  DELETE_SEARCH_FILTER,
  GET_DEPARTMENT_ONLY_PROVIDERS,
  GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS,
  ADD_NEW_PATIENT_PROCEDURE,
  GET_LOCATION,
  GET_TIMEZONE,
  GET_LOCATION_TIMEZONE,
  GET_DEPARTMENT_SUPPORTED_KEYS,
  VALIDATE_EMAIL_UNIQUNESS,
  VALIDATE_PHONE_UNIQUNESS,
  GET_SEARCH_CATEGORY,
  GET_COOKIE_CONSENT_TEXT,
  POST_COOKIE_CONSENT_PROVIDED,
  GET_ALL_LOCATIONS,
  GET_ALL_PROCEDURES_MODIFIERS
} from "./constants";

export const getDepartmentProcedures = () =>
  axios.get(GET_DEPARTMENT_PROCEDURES);

export const getDepartmentProviders = () => axios.get(GET_DEPARTMENT_PROVIDERS);

export const getDepartmentOnlyProviders = () =>
  axios.get(GET_DEPARTMENT_ONLY_PROVIDERS);

export const getDepartmentOnlyGeneralCardiologists = () =>
  axios.get(GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS);

export const saveFilterSelection = (params) =>
  axios.post(SAVE_SEARCH_FILTER, params);

export const updateFilterSelection = (params) => {
  const url = UPDATE_SEARCH_FILTER.replace(":id", params.id);
  return axios.put(url, params);
};

export const deleteCustomFilter = (id) => {
  const url = DELETE_SEARCH_FILTER.replace(":id", id);
  return axios.delete(url);
};

export const addNewPatientProcedure = (params) =>
  axios.post(ADD_NEW_PATIENT_PROCEDURE, params);

export const getLocation = (params) =>
  fetch(
    `${GET_LOCATION}?components=country%3AUS|postal_code%3A${params.postal_code}&key=${process.env.REACT_APP_MAP_KEY}`
  );

export const getTimezone = (params) =>
  fetch(
    `${GET_TIMEZONE}?location=${params.location}&timestamp=${params.timestamp}&key=${process.env.REACT_APP_MAP_KEY}`
  );

export const getLocationTimezone = (params) =>
  axios.get(GET_LOCATION_TIMEZONE, { params });

export const getDepartmentSupportedKeys = () =>
  axios.get(GET_DEPARTMENT_SUPPORTED_KEYS);

export const isEmailUnique = (params) =>
  axios.get(VALIDATE_EMAIL_UNIQUNESS,{params});

export const isPhoneUnique = (params) =>
  axios.get(VALIDATE_PHONE_UNIQUNESS, {params});

export const getSearchCategoryList = () => axios.get(GET_SEARCH_CATEGORY);

export const getCookieConsentTextApiCall = () => defaultAxios.get(GET_COOKIE_CONSENT_TEXT);

export const postCookieConsentProvided = (params) => axios.post(POST_COOKIE_CONSENT_PROVIDED, params);

export const getAllLocations = async () => {
  const maxRetryAttempts = 3;
  const retryDelay = 2000; // milliseconds

  let retryAttempts = 0;

  while (retryAttempts < maxRetryAttempts) {
    try {
      // axiosWithAuthAndCamelcase is an axios instance to prevent token refreshment (if the activity the user does is of short time and requires no token refreshment)
      const response = await axiosWithAuthAndCamelcase.get(GET_ALL_LOCATIONS);

      // Check the response for undesired conditions
      if (response.data.allLocations.length <= 0) {
        retryAttempts++;

        if (retryAttempts < maxRetryAttempts) {
          await new Promise(resolve => setTimeout(resolve, retryDelay));
        } else {
          // Handle the case when the desired response is not received after max attempts
          return null; // Return null or handle the error as needed
        }
      } else {
        // Return the desired response
        return response;
      }
    } catch (error) {
      retryAttempts++;

      if (retryAttempts < maxRetryAttempts) {
        await new Promise(resolve => setTimeout(resolve, retryDelay));
      } else {
        // Handle the case when the API request fails after max attempts
        return null; // Return null or handle the error as needed
      }
    }
  }
};


// export const getAllProceduresModifiers = () =>
//   axios.get(GET_ALL_PROCEDURES_MODIFIERS)

export const getAllProceduresModifiers = async () => {
  const maxRetryAttempts = 3;
  const retryDelay = 2000; // milliseconds

  let retryAttempts = 0;

  while (retryAttempts < maxRetryAttempts) {
    try {
      // axiosWithAuthAndCamelcase is an axios instance to prevent token refreshment (if the activity the user does is of short time and requires no token refreshment)
      const response = await axiosWithAuthAndCamelcase.get(GET_ALL_PROCEDURES_MODIFIERS);

      // Check the response for undesired conditions
      if (response.data.allModifiers.length <=0 ) {
        retryAttempts++;

        if (retryAttempts < maxRetryAttempts) {
          await new Promise(resolve => setTimeout(resolve, retryDelay));
        } else {
          // Handle the case when the desired response is not received after max attempts
          return null; // Return null or handle the error as needed
        }
      } else {
        // Return the desired response
        return response;
      }
    } catch (error) {
      retryAttempts++;

      if (retryAttempts < maxRetryAttempts) {
        await new Promise(resolve => setTimeout(resolve, retryDelay));
      } else {
        // Handle the case when the API request fails after max attempts
        return null; // Return null or handle the error as needed
      }
    }
  }
};