export default {
  EDIT_APPOINTMENT_CONFIRMATION_MODAL: 'Changing the date/time in this app is not an official re-scheduling of your appointment. If you are seeking to change your appointment, you must contact your provider to do so. Once that step is completed, you may edit the appointment in this app.',
  SELECT_PROCEDURE_NOTE: 'Please select a procedure to view. You can always select a different procedure at any time at the top of your screen.',
  DID_NOT_RECEIVE_VERIFICATION_CODE: 'Didn\'t receive your code?',
  MSG_ENTER_VERIFICATION_CODE: 'Please enter the Verification Code sent to mobile number',
  BTN_RESENT_CODE: 'Resend Code',
  LBL_EDIT_APPT: 'Important:',
  MSG_EDIT_APPT: 'If your procedure has been scheduled, do not leave it blank. Secure Start℠ is most helpful to you if we can give you tasks and reminders in relation to your procedure date.',
  MSG_EDIT_APPT_SMALL: 'You may ignore if your procedure date/time is yet to be decided.',
  SUMMERY_MSG_CAREGIVER: 'Secure Start℠ allows you to add caregivers, such as family members, to help you throughout your surgical journey. Only add those you trust– they will have full access to your Secure Start℠ account.',
  SWITCH_PROCEDURE_CONFIRMATION_MODAL: 'Are you sure you want to change your procedure view from %procedure1 to %procedure2',
  NO_RESULTS_FOUND_HEADER: 'No results found for "%searchTerm".',
  NO_RESULTS_SUBTEXT1: '# Try searching using a different keyword or check your spelling.',
  NO_RESULTS_SUBTEXT2: '# You can also create a new question if you didn\'t find what you were looking for.',
  WELCOME_HEADER: 'Welcome to Secure Start℠!',
  WELCOME_HEADER_BODY: 'Thank you for signing up',
  WELCOME_MSG_1: 'To begin, we will need to gather some information relating to your procedure.',
  WELCOME_MSG_2: 'This will allow us to send you relevant notifications and tasks at specific times throughout your surgical journey.',
  WELCOME_MSG_1_INVITED_USER: 'Welcome to Secure Start℠, Important updates related to your procedure will appear on the notifications tab.',
  WELCOME_MSG_2_INVITED_USER: 'Thank you for giving us a chance to serve you - Team Secure Start℠.',
  WELCOME_MSG_FOOTER: 'Best of luck!',
  INVITE_CODE_CREATE_PROCEDURE: 'If you want to create a new procedure, please provide the invite code below.',
  MSG_PROCEDURE_COMPLETED: 'since your procedure is complete, all the information related to it has been deleted.',
  REQUEST_DOWNLOAD_DATA_INFORMATION: 'You can download a copy of your \'Secure Start℠\' information. Once your data is prepared for download, we will inform you via email/text about the same.',
  PASSWORD_CONSTRAINT_INFORMATION: 'Password must have at least 10 characters, 1 uppercase, 1 lowercase letter and 1 number.',
  SUPPORT_EMAIL: 'support@uk.securestart.app',
  SUPPORT_PHONE_NUMBER: "+44 808 1694880",
  LOGIN_SCREEN_MESSAGE: "As an Advisor, the Ostomy Journey Companion portal gives you visibility and tools to understand the engagement activities of and communicate with consumers using the app.",
  PATIENT_REGISTER_MOBILE_VERIFICATION_PROMPT: "Enter the new verification code sent to:",
  PATIENT_REGISTER_EMAIL_VERIFICATION_PROMPT: "Enter the new verification code sent to:",
  PROCEDURE_VERIFICATION_PHONE_NUMBER: "+44 800 3761310",

  NAME_VIEW_MESSAGE_HEADING: "Hi, let’s get started!",
  NAME_VIEW_MESSAGE_BODY: "First, let’s gather some basic details to help us reach you.",
  NAME_VIEW_NEXT_BUTTON_TEXT: "Next: Address",
  ADDRESS_VIEW_MESSAGE_HEADING: "Now let’s get your address",
  ADDRESS_VIEW_MESSAGE_BODY: "This information helps us provide you with product samples or connect you with local resources.",
  ADDRESS_VIEW_BUTTON_TEXT: "Next: Procedure Dates",
  PROCEDURE_DATES_VIEW_MESSAGE_HEADING: "Next, let’s focus on your ostomy surgery",
  PROCEDURE_DATES_VIEW_MESSAGE_BODY: "Sharing these dates helps us provide you with relevant and timely information and tips.",
  PROCEDURE_DATES_VIEW_NOTE: "Note: If you do not know your surgery date, it can be added later.",
  PROCEDURE_DATES_VIEW_BUTTON_TEXT: "Next: Procedure Details",
  PROCEDURE_DETAILS_VIEW_MESSAGE_HEADING: "Help us better serve you",
  PROCEDURE_DETAILS_VIEW_MESSAGE_BODY: "Let’s customize your experience to serve your stoma support needs.",
  PROCEDURE_DETAILS_VIEW_BUTTON_TEXT: "Next: Service Agreement",
  SERVICE_AGREEMENT_VIEW_MESSAGE_HEADING: "Service Agreement",
  SERVICE_AGREEMENT_VIEW_BUTTON_TEXT: "Next: Confirm",
  PROCEDURE_INFO_MODAL_HEADING: "Procedure",
  PROCEDURE_INFO_MODAL_BODY: '<div style="text-align: left">Please choose one type of stoma that is reflective of your procedure.<br/><br/><b>Colostomy</b>: a stoma for fecal output created in the colon (large intestine).<br/><br/><b>Ileostomy</b>: a stoma for fecal output created in the small intestine.<br/><br/><b>Urostomy</b>: a stoma created to drain urine.<br/><br/><span style=\"font-color: pink\">Note: If you have more than one stoma, you can adjust your education preferences in the next question.</div>',
  EDUCATION_PREFERENCE_INFO_MODAL_HEADING: "Education Preferences",
  EDUCATION_PREFERENCE_INFO_MODAL_BODY: '<div style="text-align: left">You have the option to customize the amount of educational information shown to you in this app.<br/><br/>If you have more than one stoma, or you are interested in learning about other types of stomas, you may choose to select “View for all stoma types.”<br/><br/>You can also change your selection later in the app after completing the login process.',
  SUCCESS_VIEW_URL_TEXT: `You will be redirected soon, if not redirected, <a href="deepLinkUrl" target="_blank"> click here</a>`,
  LOGGEDIN_PROCEDURE_DETAILS_TITLE: "Procedure Details",
  LOGGEDIN_PROCEDURE_DETAILS_UNAUTHORIZED_TITLE: "You cant add any new procedures"
};
