import React from "react";
import "../patientRegister.scss";
import PropTypes from "prop-types";
import moment from "moment";
import Stack from "@mui/material/Stack";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import { patientRegisterReducer } from "../reducers";
import Password from "../../../components/StyleGuideComponents/Password";
import PrimaryButton from "../../../components/StyleGuideComponents/PrimaryButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from '@mui/icons-material/Cancel';
import validate from "common/validator";
import { passwordValidationConfig } from "./../validators";
import { createPatient } from "../action";
import { trimCountryCodeFromPhoneNumber } from 'utils/stringUtils'
import {
  SUCCESS_VIEW
  } from "./../constants";


class PasswordView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      timer: 6,
      errors: [],
      upperCaseTestHover: false,
      lowerCaseTestHover: false,
      numericalTestHover: false,
      passwordLengthTestHover: false,
      showPasswordValidations: false
    };
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };
  
  onValidationFailure = (errors) => {
    this.setState({ errors: errors });
  };

  onNextValidationSuccess = () => {
    const params = {
      first_name: this.props.firstName,
      last_name: this.props.lastName,
      email: this.props.email,
      contact_number: trimCountryCodeFromPhoneNumber(this.props.phone)[0] == '0' ? trimCountryCodeFromPhoneNumber(this.props.phone).replace('0','') : trimCountryCodeFromPhoneNumber(this.props.phone),
      location_code: this.props.facilityCode,
      procedure_modifier: this.props.modifierIdentifier.value,
      procedure_date: this.props.surgeryDate ? moment(new Date(this.props.surgeryDate)).format('YYYY-MM-DD') : null,
      stoma_marking_date: this.props.stomaMeasuringAppointment ? moment(new Date(this.props.stomaMeasuringAppointment)).format('YYYY-MM-DD') : null,
      email_verification_code: this.props.emailOtp,
      contact_number_verification_code: this.props.phoneOtp,
      flow_type: this.props.flowType,
      flow_id: this.props.flowId,
      password: this.state.password,
      password_confirmation: this.state.password,
      has_marketing_consent: this.props.marketingConsent,
      has_user_consented: this.props.serviceEnrollment,
      is_procedure_valid: this.props.isProcedureValid,
      facility_id: this.props.facilityId.value,
      education_preference: this.props.educationPreference.value,

      address:{
        line_1: this.props.addressLine1,
        line_2: this.props.addressLine2,
        city: this.props.city,
        state: this.props.state,
        zipcode: this.props.zipCode.trim().replaceAll('.','').replaceAll('-','')
      }
    };
    this.props.createPatient(params,this.props.nextPage,SUCCESS_VIEW);
  };

  checkIfDisabled = () =>{
    return this.state.password.length >= 10 && /[A-Z]/.test(this.state.password) && /[a-z]/.test(this.state.password) && /[0-9]/.test(this.state.password)
  }

  validatePassword = () => {
    validate(
      passwordValidationConfig,
      this.state,
      this.onValidationFailure,
      this.onNextValidationSuccess
    );
  };

  render() {
    return (

          <div className="text-center">
            <Stack spacing={"8px"}>


              <div>
                <div className="dark-highlight otp-message">
                  Please set your Password.
                </div>
              </div>

              <Password
                onFocus={()=>{this.setState({showPasswordValidations:true})}}
                id="password"
                label="Password"
                name="password"
                value={this.state.password}
                onChange={(e) => this.handleChange("password", e.target.value)}
                required
                error={"password" in this.state.errors}
                errorText={
                  "password" in this.state.errors
                    ? this.state.errors.password
                    : ""
                }
              />

              <div className={`${this.state.showPasswordValidations ? "password-tests-visible":"password-tests-hidden"} resend-message password-tests`}>
                <div className="password-must-message">Password must have at least</div>

                <div className="resend-code password-verify-check-box-wrapper">
                  <div className="password-verify-check-box-inner-wrapper">
                    <div className="password-verify-check-box">

                      {/[A-Z]/.test(this.state.password) ? (
                        <CheckCircleIcon
                        className="password-test-icon"
                          fontSize="large"
                          sx={{ color: "#34C759" }}

                        />
                      ) : (

                              <CancelIcon
                              fontSize="large"
                              sx={{ color: "#DB5461" }}
                            />


                      )}
                      <span>1 uppercase</span>
                    </div>
                    <div className="password-verify-check-box">
                      {/[a-z]/.test(this.state.password) ? (
                        <CheckCircleIcon
                          fontSize="large"
                          sx={{ color: "#34C759" }}
                        />
                      ) : (
                        <CancelIcon
                        fontSize="large"
                        sx={{ color: "#DB5461" }}
                      />
                      )}
                      <span>1 lowercase</span>
                    </div>
                  </div>

                  <div className="password-verify-check-box-inner-wrapper">
                    <div className="password-verify-check-box">
                      {/[0-9]/.test(this.state.password) ? (
                        <CheckCircleIcon
                          fontSize="large"
                          sx={{ color: "#34C759" }}
                        />
                      ) : (
                        <CancelIcon
                        fontSize="large"
                        sx={{ color: "#DB5461" }}
                      />
                      )}
                      <span>1 number</span>
                    </div>
                    <div className="password-verify-check-box">
                      {this.state.password.length >= 10 ? (
                        <CheckCircleIcon
                          fontSize="large"
                          sx={{ color: "#34C759" }}
                        />
                      ) : (
                        <CancelIcon
                        fontSize="large"
                        sx={{ color: "#DB5461" }}
                      />
                      )}
                      <span>10 characters</span>
                    </div>
                  </div>
                </div>

              </div>

              <PrimaryButton
              disabled={!this.checkIfDisabled()}
                fullWidth
                text="Set Password"
                onClick={this.validatePassword}
              />
            </Stack>
          </div>

    );
  }
}

PasswordView.propTypes = {
  isLoading: PropTypes.bool,
};


const mapStateToProps = (state) => ({
  firstName: state.patientRegister.patientRegisterReducer.firstName,
  lastName: state.patientRegister.patientRegisterReducer.lastName,
  email: state.patientRegister.patientRegisterReducer.email,
  phone: state.patientRegister.patientRegisterReducer.phone,
  addressLine1: state.patientRegister.patientRegisterReducer.addressLine1,
  addressLine2: state.patientRegister.patientRegisterReducer.addressLine2,
  city: state.patientRegister.patientRegisterReducer.city,
  state: state.patientRegister.patientRegisterReducer.state,
  zipCode: state.patientRegister.patientRegisterReducer.zipCode,
  stomaMeasuringAppointment:state.patientRegister.patientRegisterReducer.stomaMeasuringAppt,
  surgeryDate: state.patientRegister.patientRegisterReducer.surgeryDate,
  modifierIdentifier: state.patientRegister.patientRegisterReducer.modifierIdentifier,
  facilityCode: state.patientRegister.patientRegisterReducer.facilityCode,
  facilityId: state.patientRegister.patientRegisterReducer.facilityId,
  educationPreference: state.patientRegister.patientRegisterReducer.educationPreference,
  emailOtp: state.patientRegister.patientRegisterReducer.emailOtp,
  phoneOtp: state.patientRegister.patientRegisterReducer.phoneOtp,
  flowType: state.patientRegister.patientRegisterReducer.flowType,
  flowId: state.patientRegister.patientRegisterReducer.flowId,
  hasUserConsented: state.patientRegister.patientRegisterReducer.hasUserConsented,
  serviceEnrollment: state.patientRegister.patientRegisterReducer.serviceEnrollment,
  isProcedureValid: state.patientRegister.patientRegisterReducer.isProcedureValid,
  marketingConsent:
    state.patientRegister.patientRegisterReducer.marketingConsent,
});

const mapDispatchToProps = (dispatch) => ({
  createPatient: (params,nextPage,page) =>
  dispatch(createPatient(params,nextPage,page)),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "patientRegister",
  reducer: combineReducers({
    patientRegisterReducer,
  }),
});

export default compose(withReducer, withConnect)(PasswordView);
