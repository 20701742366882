import React from "react";
import "../patientRegister.scss";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import { patientRegisterReducer } from "../reducers";
import InputTextField from "../../../components/StyleGuideComponents/InputTextField";
import PrimaryButton from "../../../components/StyleGuideComponents/PrimaryButton";
import validate from "common/validator";
import { phoneOtpValidationConfig } from "./../validators";
import { generateOtp, verifyOtp, setPhoneOtpInState } from "../action";
import { trimCountryCodeFromPhoneNumber } from 'utils/stringUtils'
import { PASSWORD_VIEW } from "./../constants";
import STRINGS from "../../../strings";

class MobileOTPVerifyView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flowId: props.flowId || null,
      flowType: props.flowType || "sign_up",
      firstName: props.firstName || "",
      phoneOtp: props.phoneOtp || "",
      timer: 60,
      errors: [],
      phone: props.phone || "",
      email: props.email || "",
      zipCode: props.zipCode || "",
    };
  }

  startTimer = () =>{
    this.timer = setInterval(() => {
      if (this.state.timer > 0) this.setState({ timer: this.state.timer - 1 });
    }, 1000);
  }
  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  componentDidUpdate() {
    if (this.state.timer === 0) {
      clearInterval(this.timer);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.firstName != nextProps.firstName) {
      this.setState({ firstName: nextProps.firstName });
    }
    if (this.state.phone != nextProps.phone) {
      this.setState({ phone: nextProps.phone });
    }
    if (this.state.email != nextProps.email) {
      this.setState({ email: nextProps.email });
    }
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onValidationFailure = (errors) => {
    this.setState({ errors: errors });
  };

  onNextValidationSuccess = () => {
    const params = {
      verification_code: this.state.phoneOtp,
      flow_type: this.state.flowType,
      flow_id: this.state.flowId,
      contact_number: trimCountryCodeFromPhoneNumber(this.state.phone),
      email: this.state.email
    }
    this.props.setOtpInReduxState({phoneOtp:this.state.phoneOtp})
    this.props.verifyPhoneOtp(params,this.props.nextPage,PASSWORD_VIEW)
  };
  handleOTP = (isResend) => {
    const params = {
      flow_type: this.state.flowType,
      flow_id: this.state.flowId,
      contact_number: this.state.phone,
    };
    this.props.generatePhoneOtp(params, this.props.nextPage,"",false, isResend);
  }
  validateOTP = (e) => {
    e.preventDefault();
    validate(
      phoneOtpValidationConfig,
      this.state,
      this.onValidationFailure,
      this.onNextValidationSuccess
    );
  };

  resetTimer = () =>{
    this.handleOTP(true);
    this.setState({timer:60},()=>this.startTimer())
  }

  render() {
    return (

          <div className="text-center">
            <Stack spacing={"8px"}>

              <div>
                <div className="otp-message">
                  {STRINGS.PATIENT_REGISTER_MOBILE_VERIFICATION_PROMPT}
                  <br />
                  <span className="verification-highlight">{this.props.phone}</span>
                </div>
              </div>
              <InputTextField
                id="phoneOtp"
                label="Verification Code"
                name="phoneOtp"
                value={this.state.phoneOtp}
                onChange={ this.handleChange }
                required
                error={"phoneOtp" in this.state.errors}
                errorText={
                  "phoneOtp" in this.state.errors
                    ? this.state.errors.phoneOtp
                    : ""
                }
              />
              <div className="resend-message">
              { this.state.timer > 0 ?
                  (
                  <div className="show-resend-code">Resend code in {this.state.timer} seconds</div>):
                  (
                    <div className="not-show-resend-code">Resend code in {this.state.timer} seconds</div>
                  )
                }               {
                  this.state.timer === 0 ?
                  (
                  <div onClick={()=>{this.resetTimer()}} className="cursor-pointer highlight-text resend-code">
                  Resend code
                  </div>
                ):(
                  <div className="cursor-disabled resend-code-disabled-text resend-code">
                  Resend code
                  </div>
                )
                }


              </div>
              <PrimaryButton
                disabled={this.state.phoneOtp.length<=0}
                fullWidth
                text="Verify"
                onClick={this.validateOTP}
              />
            </Stack>
          </div>

    );
  }
}

MobileOTPVerifyView.propTypes = {
  isLoading: PropTypes.bool,
  firstName: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
};

const mapStateToProps = (state) => ({
  firstName: state.patientRegister.patientRegisterReducer.firstName,
  phone: state.patientRegister.patientRegisterReducer.phone,
  email: state.patientRegister.patientRegisterReducer.email,
  zipCode: state.patientRegister.patientRegisterReducer.zipCode,
  flowType: state.patientRegister.patientRegisterReducer.flowType,
  flowId:
    state.patientRegister.patientRegisterReducer.flowId,
});

const mapDispatchToProps = (dispatch) => ({
  generatePhoneOtp: (params,nextPage,page,redirect=true, isResend=false) => dispatch(generateOtp(params,nextPage,page,redirect, isResend)),
  verifyPhoneOtp: (params,nextPage,page) => dispatch(verifyOtp(params,nextPage,page)),
  setOtpInReduxState: (params) =>
  dispatch(setPhoneOtpInState(params)),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "patientRegister",
  reducer: combineReducers({
    patientRegisterReducer,
  }),
});

export default compose(withReducer, withConnect)(MobileOTPVerifyView);
